@import "import";


.form-group {

  & label {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
    color: $gray600;
    letter-spacing: 1px;
  }
}


.form-control {
  height: 48px;
  background: $gray200;
  color: $basic;

  &:focus {
    background: $white;
    box-shadow: none;
    border-color: $primary;
  }

}

.form-text {

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.input-group-text {
  padding: 0;
  justify-content: center;
}

.input-group-text {
  min-width: 46px;
}