.footer {
    background: $basic;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &__logo {
        color: $white;

        @include media-breakpoint-down(md) {
           margin-bottom: 24px;
        }
    }

    &__copyright {
        margin: 0;
        padding: 0;
        opacity: 0.8;
    }
    
    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
    }

    &__link {
        color: $white;
        opacity: 0.8;
        text-decoration: underline;

        @include media-breakpoint-down(md) {
           display: block; 
           margin-bottom: 16px;
        }

        &:hover {
            color: $white;
            opacity: 1;
        }
    }
    
    &__age {
        background: $danger;
        color: $white;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 14px;
        padding: 4px;
    }

}