.nav-tabs {
    position: relative;
    top: 1px;
    border-bottom: none;  
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap; 
}

.nav-link {
    color: $primary;
    border: 1px solid transparent;
    white-space: nowrap;
}

.nav-link.active { 
    color: $basic !important;
    font-weight: 500;
    background: $white !important;
    border-color: $gray300 $gray300 $white !important;
    
}