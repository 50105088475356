@import "import";


.card {
  background: $white;
  border-radius: 4px;
}

.card-sx {
  padding: 8px;
}

.card-sm {
  padding: 16px;
}

.card-md {
  padding: 24px;
}

.card-lg {
  padding: 32px;
}

.card-shadows {
  box-shadow: 0 4px 6px 0 rgba(9,63,179,.08);
}

.card-border {
  border: 1px solid $gray300;
}