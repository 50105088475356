.home-top {
    background-color: $gray200;
    background-image: url("/static/dist/img/hometop_bg.svg");
    background-repeat: no-repeat;
    background-position: bottom center;

    &__content {
        padding: 64px 0;

        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
    }

    &__head {
        font-size: 32px;

        @include media-breakpoint-down(md) {
           font-size: 24px;
        }
    }

    &__img-wrap {
        display: flex;
        align-items: start;
        justify-content: center;
    }

    &__img {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}