.offcanvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    transition-duration: .5s;
    height: 100%;
    width: 80%;
    transform: translateX(-100%);
  
  
    &_active {
        background: $white;
        z-index: 1050;
        transition-duration: .5s;
        transform: translateX(0);
        box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.15);

        @include media-breakpoint-up(sm) {
            width: 50%;
         }
    }

    &__logo {
        padding: 16px 0;
        border-bottom: 1px solid $gray300;        
    }

    &__content {
        position: relative;
        padding: 0 24px;
        height: 100%;
    }

    &__menu-item {
        display: block;
        color: $basic;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 1px solid $gray300;
        padding: 16px 0;
    }

    &__footer {
        position: absolute;
        bottom: 0;
        border-top: 1px solid $gray300;
    }

    
  
  }