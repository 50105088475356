.company-card {
  padding: 48px 0;
  background-image: url("/static/dist/img/card-bg.png");
  background-attachment: fixed;

  @include media-breakpoint-down(md) {
    padding: 16px 0;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 800px;
    padding: 24px 0;

    @include media-breakpoint-down(md) {
      display: block;
      padding: 16px 0;
      width: 100%;
      text-align: center;
    }
  }

  &__body {
    background: $white;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 4px 6px 0 rgba(9,63,179,0.08);

    @include media-breakpoint-down(sm) {
      padding: 24px;
    }
  }

  &__head {
    font-size: 24px;
  }

  &__logo {

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  &__short-head {
    font-size: 14px;
    color: $gray600;
  }

  &__info {
    border-bottom: 1px solid $gray300;
    padding-bottom: 16px;
  }

  &__info-item {
    display: flex;
    margin-bottom: 24px;

    & span {
      min-width: 60px;
      margin-right: 8px;
      color: $gray600;
    }
  }


  &__contacts {
    border-bottom: 1px solid $gray300;
    padding: 32px 0;
  }

  &__contacts-item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      margin-right: 8px;
      color: $gray600;
    }
  }

  &__phone-extnumber {
    font-weight: 500;
    color: $gray600;
  }

  &__payment {
    border-bottom: 1px solid $gray300;
    padding: 32px 0;
  }

  &__payment-item {
    text-transform: uppercase;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      min-width: 280px;
      color: $gray600;
    }
  }

  


  &__payment-values {
    display: flex;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }


  &__manager {
    padding-top: 32px;
  }

  &__manager-item {
    display: flex;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      min-width: 280px;
      color: $gray600;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
    }
  }

  &__footer-info {
    font-size: 14px;
  }

  &__footer-logo {
    width: 120px;

    @include media-breakpoint-down(md) {
      margin-top: 16px;
    }
  }
}