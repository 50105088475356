.home-functions {
    background: $white;
    padding: 48px 0;

    &__item {
        background: $gray200;

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }
    }

    &__item-img-wrap {
        height: 300px;
        padding: 32px 0;

        @include media-breakpoint-down(md) {
            height: 100%;
            padding: 32px;
            padding-bottom: 0;
        }
    }

    &__item-img {
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        
    }

    &__item-content {
        padding: 24px;
    }

    &__item-caption {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: $gray600;
    }

    &__item-title {
        font-size: 18px;
        font-weight: 500;
    }
}