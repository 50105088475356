h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    line-height: 1.3;
    margin-bottom: 0;
}

h1 { 
    font-size: 36px;
}

h2 { 
    font-size: 32px;
}

h3 { 
    font-size: 30px;
}

h4 { 
    font-size: 26px;
}

h5 { 
    font-size: 22px;
}

h6 { 
    font-size: 18px;
}

a {
    color: $primary;
}

.alert-title {
    color: $basic !important;
    font-size: 24px !important;
}