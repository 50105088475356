.company-list {

  &__head {

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 16px !important;
    }
  }

  &__head-title {

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }

  &__head-btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__item {

  }

  &__item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__item-name {
    font-size: 16px;
    font-weight: 500;
  }

  &__item-logo {
    

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__item-info-block {
    display: flex;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__item-info {
    font-size: 14px;

    & span {
      font-weight: 500;
      color: $gray600;
    }
  }

  &__item-footer {
    border-top: 1px solid $gray300;
    padding: 8px 16px;
  }

  &__item-manager {
    cursor: pointer;
  }

  &__item-send {
    border-right: 1px solid $gray300;
    border-left: 1px solid $gray300;
    margin-right: 16px;
    padding: 0 16px;
  }

  &__item-send-btn {
    cursor: pointer;
  }

  &__item-send {
    font-size: 14px;
  }
}


