.company-detail {

  // @include media-breakpoint-down(md) {
  //   padding: 24px;
  //   margin-bottom: 24px;
  // }

  &__block {
    background: $white;
    padding: 32px;
    border: 1px solid $gray300;
    margin-bottom: 16px;
  }

  &__back-link {
    display: inline-block;
    color: $gray600;
    padding: 24px 0;

    &:hover {
      text-decoration: none;
    }
  }

  &__short-name {
    font-size: 24px;
    font-weight: 700;
  }

  &__full-name {
    font-size: 14px;
    font-weight: 500;
    color: $gray600;
  }

  &__info {
    border-bottom: 1px solid $gray300;
    padding-bottom: 16px;
  }

  &__info-item {
    display: flex;
    margin-bottom: 24px;

    & span {
      min-width: 60px;
      margin-right: 8px;
      color: $gray600;
    }
  }


  &__contacts-item {
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      display: block !important;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      margin-right: 8px;
      color: $gray600;
    }
  }

  &__phone-extnumber {
    font-weight: 500;
    color: $gray600;
  }


  &__payment-item {
    padding: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid $gray300;

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      font-size: 14px;
      font-weight: 500;
      color: $gray600;
    }
  }

  &__manager-item {
    display: flex;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      min-width: 220px;
      color: $gray600;
    }
  }

  &__tools {
    background: $white;
    padding: 16px;
  }


  &__card-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $gray300;
    border-radius: 3px;
    padding: 8px;
    margin: 0;

    & i {
      color: $gray600;
    }
  }

  &__card-link-desc {
    line-height: 12px;
  }

  &__invite-item {
    margin-bottom: 16px;
  }

  &__invite-item-remove {
    color: $danger;
  }


}