.accounts-list {

    &__item {
        padding: 16px 0;
        border-bottom: 1px solid $gray300;

        &:last-child {
            border-bottom: none;
        }
    }

    &__item-label {
        text-transform: uppercase;
        font-size: 12px;
        color: $gray600;
    }

    &__item-info {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }

    &__item-edit {
        font-size: 14px;
    }

    &__item-del {
        font-size: 14px;
        color: $danger;
    }
}