.phones-list {

    &__item {
        margin-bottom: 24px;
    }

    &__item-content {
        display: flex;
    }

    &__item-number {
        margin-right: 16px;
    }

    &__item-label {
        text-transform: uppercase;
        font-size: 12px;
        color: $gray600;
    }
}