@import "import";


.btn, .btn.swal2-styled.swal2-confirm {
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0 24px;
  line-height: 38px;
  font-size: 14px;
  user-select: none;
  transition: all .15s ease-in-out;
}

.btn-lg {
  padding: 0 24px;
  font-size: 16px;
  line-height: 54px;
}

.btn-sm {
  padding: 0 16px;
  font-size: 12px;
  line-height: 30px;
}



.btn-primary {
  color: $white;
  background: $primary !important;

  &:hover {
    background: darken($primary, 5%);
    color: $white;
    text-decoration: none;
  }

  &:focus {
    background: darken($primary, 10%);
    color: $white;
    text-decoration: none;
  }

}



.btn-success {
  color: $white;
  background: $success !important;

  &:hover {
    background: darken($success, 5%);
    color: $white;
    text-decoration: none;
  }

  &:focus {
    background: darken($success, 10%);
    color: $white;
    text-decoration: none;
  }

}


.btn-warning {
  color: $white;
  background: $warning;

  &:hover {
    background: darken($warning, 5%);
    color: $white;
    text-decoration: none;
  }

  &:focus {
    background: darken($warning, 10%);
    color: $white;
    text-decoration: none;
  }

}



.btn-danger {
  color: $white;
  background: $danger;

  &:hover {
    background: darken($danger, 5%);
    color: $white;
    text-decoration: none;
  }

  &:focus {
    background: darken($danger, 10%);
    color: $white;
    text-decoration: none;
  }

}
