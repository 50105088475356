.vcards {

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border-radius: 3px;
        background: $white;
    }

    &__item-head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: lighten($primary, 5%);
        background-image: url("/static/dist/img/hometop_bg.svg");
        background-blend-mode: multiply;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        padding: 24px 0;
        border-radius: 3px 3px 0 0;
    }

    &__item-img {
        width: 120px;
        height: 120px;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        margin-bottom: 16px;
    }

    &__item-name {
        flex-wrap: wrap;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__item-lastname {
        width: 100%;
        text-align: center;
    }

    &__item-company {
        color: $white;
        min-height: 24px;
    }

    &__item-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &__item-info {
        position: relative;
        min-height: 244px;
        padding: 24px 32px;
    }

    &__item-contact {
        margin-bottom: 16px;

        & span {
            position: relative;
            display: inline-block;
            min-width: 88px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: $gray600;
        }
    }

    &__social {
        display: flex;
    }

    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 2px;
        color: $primary;
        background: $gray300;
        padding: 8px;
        margin-right: 8px;

        &:hover {
            text-decoration: none;
        }
    }

    &__item-footer {

    }

    &__footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 32px;
        border-top: 1px solid $gray300;

        &_manage {
            display: flex;
        }
    }

    &__item-edit {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray200;
        color: $primary;
        width: 40px;
        height: 40px;
        border-radius: 3px;
        margin-right: 8px;

        &:hover {
            color: $primary;
            text-decoration: none;
            background: $gray300;
        }
    }

    &__item-del {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray200;
        color: $danger;
        width: 40px;
        height: 40px;
        border-radius: 3px;

        &:hover {
            color: $danger;
            text-decoration: none;
            background: $gray300;
        }
    }

    &__item-send-btn {
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;

        & i {
            color: $primary;
        }
    }
}