.vcard-add {

    &__box {
        padding: 48px;
        border: 1px solid $gray300;
        background: $white;

        @include media-breakpoint-down(sm) {
            padding: 24px;
        }
    }

    &__imd {
        display: flex;
    }

    &__img-field {
        display: none;
    }

    &__current-img {
        border-radius: 100%;
        display: inline-block;
        width: 70px;
        height: 70px;
        object-fit: cover;
        object-position: 50% 50%;
        margin-right: 15px;
        vertical-align: middle;
    }
    


}