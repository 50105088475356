.header {
  display: block;
  position: relative;
  background: $white;
  padding: 16px 0;
  z-index: 1;


  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-img {
    position: relative;
    width: 156px;
    height: auto;
    top: 5px;
  }

  &__menu-item {
    color: $basic;
    font-size: 12px;
    text-transform: uppercase;
    padding: 0 16px;
  }

  &__tools {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__user-btn {
    display: flex;
    
    &:hover {
      cursor: pointer;
    }

    & i {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gray300;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 12px;
    }
  }
  
  &__user-avatar {
    width: 48px;
    height: 48px;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
  }
}