.authform {
  padding: 48px 0;
  z-index: 0;

  &__box {
    background: $white;
    padding: 56px;

    @include media-breakpoint-down(sm) {
      padding: 24px;
    }
  }
}