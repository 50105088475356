.regform {
  padding: 48px 0;
  z-index: 0;

  & i {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 16px;
    color: $primary;
    font-size: 24px;
    width: 64px;
    height: 64px;
    border: 1px solid $primary;
    border-radius: 50%;
  }

  &__box {
    background: $white;
    padding: 56px;

    @include media-breakpoint-down(sm) {
      padding: 24px;
    }
  }
}