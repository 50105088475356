.burger {
    position: relative;
    width: 30px;
    cursor: pointer;
    margin-left: 24px;
    z-index: 10;
  
    @include media-breakpoint-up(md) {
      display: none;
    }
  
    &__line1, &__line2, &__line3 {
      width: 30px;
      height: 2px;
      background: $basic;
      margin: 8px auto;
      transition-duration: .3s;
    }
  
    &#{&}_on {
  
      .line1 {
        transform: translateY(10px) rotate(135deg);
      }
  
      .line2 {
        opacity: 0;
      }
  
      .line3 {
        transform: translateY(-10px) rotate(-135deg);
      }
    }
  
    &__menu {
      list-style: none;
      padding: 0;
    }
  
    &__menu-item {
      font-size: 18px;
      padding: 16px 0;
      border-bottom: 1px solid $gray300;
    }
  
    &__menu-link {
      color: $basic;
    }
  
  }  