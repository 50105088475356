.invite-item {

    &__head {
        margin-bottom: 8px;
    }

    &__company {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
    }

    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    &__info-item {
        font-size: 14px;
        margin-right: 24px;

        & span {
            color: $gray600;
            font-weight: 500;
        }
    }
}