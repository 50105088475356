.vcard-public {
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-image: url("/static/dist/img/card-bg.png");
    
    &__content {
        background: $white;
    }

    &__head {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: lighten($primary, 5%);
        background-image: url("/static/dist/img/hometop_bg.svg");
        background-blend-mode: multiply;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        padding: 24px 0;
    }

    &__item-img {
        width: 120px;
        height: 120px;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        margin-bottom: 16px;
    }

    &__name {
        flex-wrap: wrap;
        justify-content: center;
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__company {
        color: $white;
        min-height: 24px;
    }

    &__info {
        min-height: 244px;
        padding: 24px 32px;
    }

    &__contact-item {
        margin-bottom: 16px;

        & span {
            position: relative;
            display: inline-block;
            min-width: 88px;
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: $gray600;
        }
    }

    &__social-list {
        display: flex;
    }

    &__social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 2px;
        color: $primary;
        background: $gray300;
        padding: 8px;
        margin-right: 8px;

        &:hover {
            text-decoration: none;
        }
    }

    &__footer-info {
        color: $gray600;
        font-size: 14px;
    }
}