.profile {

    &__imd {
        display: flex;
      }
    
    &__img-field {
        display: none;
    }
    
    &__current-img {
        border-radius: 100%;
        display: inline-block;
        width: 70px;
        height: 70px;
        object-fit: cover;
        object-position: 50% 50%;
        margin-right: 15px;
        vertical-align: middle;
    }
}